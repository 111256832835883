

export default function SectionBooking1({ _data }) {
 
  return (
    <div
      className="section-full p-t120 p-b90 tw-booking-area tw-booking-area-bg-wrap"
      // style={{ backgroundImage: `url(${_data.background})` }}
    >
    
      <div className="container">
        <div className="tw-booking-section">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="tw-booking-media">
                <div className="media">
                <div className="wt-small-separator site-text-primary">
                   <div>{_data.title}</div>
             </div>
      <h2 className="wt-title">{_data.subtitle}</h2>
             <p className="section-head-text">{_data.description}</p>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="map-view img-fluid"
                src="/assets/images/trackk6.png"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
