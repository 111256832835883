import { publicUrlFor, loadScript } from "../../../../globals/constants";
import { useEffect } from "react";
export default function SectionSlider2() {
  useEffect(() => {
    loadScript("/assets/js/slider-home2.js", true);
    loadScript("/assets/js/bg-image-move.js", true);
  });
  return (
    <>
      <div className="twm-slider2-wrap">
        <div className="swiper twm-slider2">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img
                className="slider-image"
                src="/assets/images/main-slider/slider3/front7.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
