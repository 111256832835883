import { global } from "../constants";

export const data = {
  banner: {
    background: global.banner + "about.png",
    title: "Privacy Policy",
    crumb: "Privacy Policy",
  },

  whatwedo: {
    title: "What we do!",
    subtitle: "Specialist Logistics Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    image: global.icons + "img1.png",
    works: [
      {
        icon: global.icons + "warehouse.png",
        serial: "01.",
        title: "Warehousing",
        description:
          "Managing warehouse to deliver packets through 3W EV & 4W EV , with our team (Supervisors, Managers, Drivers, Delivery boys).",
      },
      {
        icon: global.icons + "corporates.png",
        serial: "02.",
        title: "On demand",
        description:
          "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys).",
      },
      {
        icon: global.icons + "ondemand.png",
        serial: "03.",
        title: "Corporate",
        description:
          "On Demand Platform for B2B customers assisting in their deliveries through 100% EV Fleet around with 600+ happy customers.",
      },
    ],
  },

  achivements: [
    {
      count: "12",
      title: "Warehouses in U.S.A",
    },
    {
      count: "15",
      title: "Year of experience work with U.S.A",
    },
    {
      count: "60",
      title: "Kg Minimum lot valume",
    },
  ],

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/1.png",
      global.logo + "/3.png",
      global.logo + "/6.png",
      global.logo + "/5.png",
      global.logo + "/8.png",
      global.logo + "/9.png",
    ],
    partners: [
      {
        pic: global.testim + "pic1.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer",
      },
      {
        pic: global.testim + "pic2.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer",
      },
      {
        pic: global.testim + "pic3.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer",
      },
      {
        pic: global.testim + "pic4.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer",
      },
    ],
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title:
          "At the end of the day, going forward, a new normal that has evolved from.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title:
          "How all this mistaken idea denouncing pleasure and praising pain was.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
    ],
  },

  approach: {
    title: "Company Approach",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "04",
    specifications: [
      {
        icon: global.icons + "1.png",
        count: "100",
        title: "Team Members",
      },
      {
        icon: global.icons + "2.png",
        count: "50000",
        title: "On Time Delivery",
      },
      {
        icon: global.icons + "3.png",
        count: "600",
        title: "Individual Customers",
      },
    ],
  },
};
